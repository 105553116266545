<template>
    <el-cascader
            @change="handleChange"
            style="width: 100%"
            collapse-tags
            :value="value"
            :show-all-levels="false"
            :filterable="true"
            :options="companyList"
            :props="{ checkStrictly: true,label:'name',value:'id', multiple: true, emitPath: false }"
            clearable>
    </el-cascader>
</template>

<script>
    export default {
        name: 'cascaderCompanyMultiple',
        props: {
            value: {
                default: []
            },
        },
        data() {
            return {
                companyList: []
            }
        },
        methods: {
            async getData() {
                this.$http.get(`/company/company/tree`)
                    .then(resp => {
                        this.companyList= resp.data.data
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
            handleChange(value) {
                // 通知父组件选中值变化
                this.$emit('input', value);
                this.$emit('change', value);
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
