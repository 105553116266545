import Mock from '../utils'

let data_items = {
    "data": [
        {
            "Id": "ee03f97bfe3240e290e435e42a42f764",
            "SportName": "一字马",
            "SportType": "健身类",
            "SportTime": "低强度",
            "MetValue": 2,
            "SportNum": "柔韧性锻炼",
            "IsEnabled": "是",
            "TAge": "8-80"
        },
        {
            "Id": "4269cc735e0648fcaff81cb94de416de",
            "SportName": "关节舒展",
            "SportType": "健身类",
            "SportTime": "中等强度",
            "MetValue": 3.7,
            "SportNum": "柔韧性锻炼",
            "IsEnabled": "是",
            "TAge": "8-80"
        },
        {
            "Id": "18e012cfe77c472b9cd10d29d4c15bcb",
            "SportName": "小腿拉伸",
            "SportType": "健身类",
            "SportTime": "低强度",
            "MetValue": 1.8,
            "SportNum": "柔韧性锻炼",
            "IsEnabled": "是",
            "TAge": "8-80"
        },
        {
            "Id": "8ef17c45127a4fea945a40483e43119c",
            "SportName": "哑铃侧平举",
            "SportType": "健身类",
            "SportTime": "低强度",
            "MetValue": 5,
            "SportNum": "力量运动",
            "IsEnabled": "是",
            "TAge": "12-65"
        },
        {
            "Id": "a06e5f038cdf4b03801299ae072e99c4",
            "SportName": "颈部舒缓",
            "SportType": "健身类",
            "SportTime": "低强度",
            "MetValue": 2.6,
            "SportNum": "柔韧性锻炼",
            "IsEnabled": "是",
            "TAge": "8-80"
        },
        {
            "Id": "6cd4ab4826554c41a4701460a2e80827",
            "SportName": "开合跳",
            "SportType": "健身类",
            "SportTime": "低强度",
            "MetValue": 3.5,
            "SportNum": "力量运动",
            "IsEnabled": "是",
            "TAge": "12-65"
        },
        {
            "Id": "588ae312dabd48daa00f7e01364afd4b",
            "SportName": "划船机（100w）",
            "SportType": "健身类",
            "SportTime": "高强度",
            "MetValue": 7,
            "SportNum": "力量运动",
            "IsEnabled": "是",
            "TAge": "15-60"
        },
        {
            "Id": "9239a083ab4a4c02815ad88fdfc530c4",
            "SportName": "普拉提",
            "SportType": "健身类",
            "SportTime": "中等强度",
            "MetValue": 5.1,
            "SportNum": "柔韧性锻炼",
            "IsEnabled": "是",
            "TAge": "8-80"
        },
        {
            "Id": "6e14dd96084b450ead18c4728475bcff",
            "SportName": "站立提踵（多组）",
            "SportType": "健身类",
            "SportTime": "高强度",
            "MetValue": 6.2,
            "SportNum": "有氧运动",
            "IsEnabled": "是",
            "TAge": "12-65"
        },
        {
            "Id": "96a7f59c6e224bef9a1c210016359540",
            "SportName": "武术（一般）",
            "SportType": "健身类",
            "SportTime": "中等强度",
            "MetValue": 3.4,
            "SportNum": "有氧运动",
            "IsEnabled": "是",
            "TAge": "8-80"
        }
    ],
    "total": 136
}

let data_template = {
    "data": [
        {
            "Id": "63cd5e4e-190f-4240-b659-e5757ca596b0",
            "TemplateName": "COPD",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "639a8e20-973d-4c3d-b37b-fdfaf5982e36",
            "TemplateName": "哮喘",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "1d00ee5e-e5ae-4900-89ce-b9b992b498ce",
            "TemplateName": "帕金森氏病",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "5672ef7d-e7e3-44a4-a73e-0aa687768fc1",
            "TemplateName": "超重和肥胖",
            "ClassfyName": "\r\n瘦身运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "3575e15e-2bbc-4c8c-b340-1cda3aafe03c",
            "TemplateName": "骨质疏松",
            "ClassfyName": "骨质疏松运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "33e21121-4c60-4e0f-9c7d-0b07e7566c23",
            "TemplateName": "多发性硬化",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "5dea6f50-4c3f-4901-bec3-1fae0495358b",
            "TemplateName": "慢性肾病",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "f471e5f6-9621-4c12-96b0-187a81c8347b",
            "TemplateName": "智力残疾",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "3b5fb226-4d28-41c1-b3db-e9c5011cbdde",
            "TemplateName": "HIV",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        },
        {
            "Id": "f982f04d-2f78-4f30-a3e9-78ad45a70fea",
            "TemplateName": "纤维肌痛",
            "ClassfyName": "康复运动方案",
            "Explains": null,
            "ExpireDay": 7
        }
    ],
    "total": 25
}

let data_principles = {
    "data": [
        {
            "Id": "f4d5b2577af548558084f4d131e3a647",
            "TITLE": "通用2",
            "CONTENTS": "1.跑步前先预热身体十分钟，散步、甩臂，拉伸身体各部位的肌肉组织，先慢后快地进行运动。\n2.运动中不宜过于用力或屏气，不宜做速度强度大、精神紧张的项目；不“省略”整理活动：运动感觉心力俱乏时，应适宜放松，有助于消除疲劳，快速恢复体力；不宜用嘴呼吸，应养成用鼻子呼吸的习惯，使气管和肺部不受尘埃、病菌的侵害。\n3.运动后，不宜立即蹲坐休息、不在大汗淋漓时洗冷热水浴，沐浴一般应在20分钟内为宜；不贪吃冷饮，不大量喝水吃糖，不立即吃饭；不宜立即吸烟或饮酒；不宜立即走进空调房或风口纳凉，避免打破正常的生理调节机能。\n4.注意运动安全防护，观察运动场所的周边环境，并采取适当的安全护具；室内运动时，光线要适宜，温度应保持20摄氏度左右，保持良好的通风环境。\n5.运动要避免受伤，科学锻炼，掌握正确的运动要领，预防运动伤病。\n6.临睡前不宜过度运动，避免大量出汗，17时至19时是晚间锻炼的最佳时段。\n7.可携带糖果，若有低血糖反应，可进食少量糖块；若有心绞痛症状，则应立即就医。\n8.如果有条件，最好在运动前后监测血压和心电图。"
        },
        {
            "Id": "72183be0b3d84be8ac653f3e634663ac",
            "TITLE": "通用1",
            "CONTENTS": "1.在运动前2小时可适量饮用运动饮料，运动中宜少量多次地补充水分。\n2.运动时应保持心情愉快，情绪激动时应暂停运动；要注意自觉症状，若有头晕、胸闷、恶心、心悸等不适感，应立即停止并对症处理。\n3.运动结束时，逐渐减小运动强度，做好整理活动，不能突然停止或坐下，以免直立性低血压。\n4.选择合适的运动服饰，如宽松、吸水性好等；选择合适的运动鞋，如质地好，尺寸合适，鞋底有弹性等特点。\n5.保证足够的体力活动，每周应运动5~7天，不宜突然进行高强度长时间的运动；运动量必须严格控制，切忌盲目，要循序渐进，强度要适可而止，如心悸、头昏、乏力应立即减量，但最好不要骤停。\n6.饭后运动应间隔1小时以上为宜， 可以适当补充水分和碳水化合物，一般选餐后1~1.5小时开始，这是降血糖的最佳时间。\n7.身体虚弱、颈椎病急性期情况下不宜做跑步等运动，以静养休息为宜。\n8.运动前后，都应适当做拉伸活动，放松肌肉，以免造成肌肉损伤和酸痛。"
        },
        {
            "Id": "0d70202e39c44c91b53e1d937e82bb76",
            "TITLE": "骨质疏松",
            "CONTENTS": "1.避免扭曲、弯曲和挤压脊柱的运动。\n2.避免爆发性和高撞击性运动。\n3.应避免过度前屈，如触摸脚尖和划船样动作等。\n4.老年人、合并有糖尿病、关节炎、心血管或肺部疾患等需要物理治疗师设计并参与到运动中。"
        },
        {
            "Id": "97a64ce85e364e46add8ed33aba3875c",
            "TITLE": "糖尿病",
            "CONTENTS": "1.避免空腹和注射药物后60~90min时运动，以免引起低血糖反应。\n2.运动易发生低血糖者，可在医嘱下调整胰岛素的剂量，或在运动前后适当增加食物的摄入量。\n3.在运动时宜随身携带含糖饮料、点心，必要时使用。\n4.空腹血糖大于16.7nmol/L时应停止运动。"
        },
        {
            "Id": "27b9d1e04903445bb59fac25a63d4fc1",
            "TITLE": "高血压病",
            "CONTENTS": "1.夏季应选择早晚进行运动，天气变冷时运动强度应降低，并避免在气温低于5℃的环境下运动。\n2.运动后血压会突然降低，延长运动后的整理活动。\n3.安静时收缩压≥180mmHg或舒张压≥110mmHg，应遵医嘱进行运动，不宜高强度剧烈运动。\n4.运动时不弯腰，头不低于心脏。"
        }
    ],
    "total": 5
}

Mock.api('/api/fitness/items', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: data_items.total,
            records: data_items.data
        }
    }
})

Mock.api('/api/fitness/template', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: data_template.total,
            records: data_template.data
        }
    }
})

Mock.api('/api/fitness/principles', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: data_principles.total,
            records: data_principles.data
        }
    }
})

Mock.api('/api/fitness/task', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: 0,
            records: []
        }
    }
})