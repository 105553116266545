import Mock from '../utils'
const Random = Mock.Random

let types = ['未开始', '进行中', '已结束']

var selectList = [
    { label: "专家投票", value: "" },
    { label: "权重评价", value: "" },
]

const makeProject = () => {
    let res = []
    for (let i = 1; i < 151; i++) {
        res.push({
            id: i,
            type: Random.pick(['权重评价', '专家投票']),
            code: '问卷编号' + i,
            name: '问卷名称' + i,
            daterange: "2023-01-01至2023-06-01",
            user: Random.pick(['联系人1', '联系人2', '联系人3']),
            created: Random.fdate(-14, -7) + ' ' + Random.ftime(),
            textData:[{
                title:"测试问题？",
                value: Random.pick(['A', 'B','C','D']),
                option:[
                    {
                        "label":"测试001",
                        "value":"A"
                    },
                    {
                        "label":"测试002",
                        "value":"B"
                    },
                    {
                        "label":"测试003",
                        "value":"C"
                    },
                    {
                        "label":"测试004",
                        "value":"D"
                    },
                ]
            }],
            latest: Random.fdate() + ' ' + Random.ftime(),
            time: Random.fdate(-7, 0) + ' ' + Random.ftime(),
            status: Random.pick(['0', '1', '2']),
        })
    }
    return res
}

let data = makeProject()

// eslint-disable-next-line no-unused-vars
Mock.api('/api/vote/types', 'get', (req, res) => {
    return types
})

// eslint-disable-next-line no-unused-vars
Mock.api('/api/vote/select', 'get', (req, res) => {
    return selectList
})

// eslint-disable-next-line no-unused-vars
Mock.api('/api/vote/table', 'get', (req, res) => {
    let qs = data
    var sh = req.query
    qs = qs.filter(item => {
        let name = item.name.includes(sh.name)
        let code = item.code.includes(sh.code)
        let status = item.status.includes(sh.status)
        if (!sh.name) {
            name = true
        }
        if (!sh.code) {
            code = true
        }
        if (!sh.status) {
            status = true
        }
        return name && status && code
    })
    return {
        total: qs.length,
        data: Mock.pagination(qs, req.query.page || 1, req.query.pageSize || 20),
    }
})

Mock.api('/api/vote/tree', 'get', (req, res) => {
    console.log(req, res)
    // 定义一个对象数组
    let qs = data.filter(e => e.status=='未开始')
    // 使用 reduce() 方法对对象数组进行分组
    let group = qs.reduce((result, item) => {
        // 查找是否已经存在该类型的分组
        let group = result.find(group => group.name === item.type);
        // 如果不存在，就创建一个新的分组
        if (!group) {
            group = { name: item.type, children: [] };
            result.push(group);
        }
        // 将该元素添加到对应的分组中
        group.children.push(item);
        return result;
    }, []);

    // 将分组后的结果转换为树状结构
    let tree = group.map(group => {
        return { name: group.name, children: group.children };
    });

    return tree

})



// 模拟上传图片接口
Mock.mock('/api/vote/upload', 'post', function (options) {
    // eslint-disable-next-line no-unused-vars
    const file = options.body.get('file') // 获取上传的文件对象
    // 模拟上传成功后返回的数据
    return {
        code: 200,
        message: '上传成功',
        data: {
            url: Random.image('200x100', '#4A7BF7', 'Mock.js')
        }
    }
})


var titleData = []

// eslint-disable-next-line no-unused-vars
Mock.api('/api/vote/form', 'post', (req, res) => {
    let form = JSON.parse(req.body)
    if(form.id){
        const index = titleData.findIndex(item => item.id === form.id);
        if (index !== -1) {
            titleData.splice(index, 1, form);
        }
    }else {
        form.id = new Date().getTime()
        form.created = new Date().format('yyyy-MM-dd hh:mm:ss')
        form.latest = Random.fdate() + ' ' + Random.ftime()
        titleData.unshift(form)
    }
    return {
        code:1,
        type: 'success',
        msg: '保存成功'
    }
})


// eslint-disable-next-line no-unused-vars
Mock.api('/api/vote/title', 'get', (req, res) => {
    let qs = titleData
    var sh = req.query
    qs = qs.filter(item => {
        let vote_id = item.vote_id.includes(sh.vote_id)
        let title = item.title.includes(sh.title)
        if (!sh.title) {
            title = true
        }
        if (!sh.vote_id) {
            vote_id = true
        }
        return vote_id && title
    })
    return {
        total: qs.length,
        data: Mock.pagination(qs, req.query.page || 1, req.query.pageSize || 20),
    }
})


// eslint-disable-next-line no-unused-vars
Mock.api('/api/vote/title/\\d+/', 'delete', (req, res) => {
    const id = req.url.match(/\d+/)[0];
    titleData = titleData.filter(item => item.id !== parseInt(id));
    return {
        code:1,
        type: 'success',
        msg: '保存成功'
    }
})
