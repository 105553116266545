<template>
    <el-cascader
            style="width: 100%"
            :value="value"
            :show-all-levels="false"
            :filterable="true"
            :options="companyList"
            :props="{ checkStrictly: true,label:'name',value:'id', emitPath: false }"
            clearable
            @change="handleChange">
    </el-cascader>
</template>

<script>
    export default {
        name: 'cascaderCompany',
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            user_type:{
                default: ""
            }
        },
        data() {
            return {
                companyList: []
            }
        },
        methods: {
            async getData() {
                this.$http.get(`/company/company/tree?user_type=${this.user_type}`)
                    .then(resp => {
                        this.companyList= resp.data.data
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
            handleChange(value) {
                // 通知父组件选中值变化
                if (value === null) {
                    this.$emit('input', "");
                }else {
                    this.$emit('input', value);
                }
                this.$emit('change', value);
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
