import Mock from 'mockjs'
const Random = Mock.Random

/**
 * [获取URL中的参数名及参数值的集合]
 * 示例URL:http://htmlJsTest/getrequest.html?uid=admin&rid=1&fid=2&name=小明
 * @param {[string]} urlStr [当该参数不为空的时候，则解析该url中的参数集合]
 * @return {Object}       [参数集合]
 */
Mock.getUrlQuery = function (urlStr) {
	let url = '?' + urlStr.split('?')[1]
	const theRequest = new Object()
	if (url.indexOf('?') != -1) {
		const str = url.substr(1)
		let strs = str.split('&')
		for (let i = 0; i < strs.length; i++) {
			theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1])
		}
	}
	return theRequest
}

Mock.pagination = function (array, pageNo, pageSize = 20) {
	try {
		pageNo = parseInt(pageNo)
		pageSize = parseInt(pageSize)
	} catch (e) {
		pageNo = 1
		pageSize = 20
	}
	if (!array) {
		return []
	}
	let offset = (pageNo - 1) * pageSize
	return offset + pageSize >= array.length
		? array.slice(offset, array.length)
		: array.slice(offset, offset + pageSize)
}

Mock.api = (url, method, callback) => {
	url += ''
	let path = url.split('?')[0]
	let keys = []
	if (path.includes(':')) {
		// /:name/:id
		path = path.replace(/:([^/]+)/g, function () {
			//:name,name
			keys.push({
				name: arguments[1],
				optional: false,
				offset: arguments[2],
			})
			return '([^/]+)'
		})
	}
	// path += '[\\/]?$'; //注意需以$结尾
	const regex = new RegExp(path)
	Mock.mock(regex, method, (req) => {
		let matches = regex.exec(req.url)
		let params = {}
		if (matches) {
			for (let i = 1; i < matches.length; ++i) {
				let name = keys[i - 1].name
				let val = matches[i]
				params[name] = val
			}
		}
		req.query = Mock.getUrlQuery(req.url)
		req.params = params
		return callback(req)
	})
}

const fdate = (start = -7, end = 0, weekday = [0, 1, 2, 3, 4, 5, 6]) => {
	const now = new Date()
	const offset = 24 * 60 * 60 * 1000 // 一天
	let dates = []
	for (var i = start; i < end; i++) {
		let day = new Date(now.getTime() + offset * i)
		if (weekday.includes(day.getDay())) dates.push(day.format('yyyy-MM-dd'))
	}
	return Random.pick(dates)
}

const ftime = (hour = [0, 23], mintues = [0, 59], second = [0, 59]) => {
	let h = (Array(2).join(0) + Random.integer(hour[0], hour[1])).slice(-2)
	let m = (Array(2).join(0) + Random.integer(mintues[0], mintues[1])).slice(-2)
	let s = (Array(2).join(0) + Random.integer(second[0], second[1])).slice(-2)
	return `${h}:${m}:${s}`
}

Mock.Random.extend({
	fdate: fdate,
	ftime: ftime,
})

export default Mock
