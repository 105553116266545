import Mock from 'mockjs'
// import './data/user.js'
// import './data/menu'
import './data/vote'
// import './data/expert'
// import './data/settings'
// import './data/dep'
// import './data/phr'
// import './data/hi'
import './data/fitness'
import './data/diet'
import './data/positive'

Mock.setup({
	timeout: '100-300',

})

Mock.mock('/api/version', () => {
	return {
		url: 'https://mingde.mriabc.com/download/',
		version: '1.2.4',
	}
})
