import Mock from '../utils'

let data_setting = {
    "data": [
        {
            "Id": "4d7dde64a6ef41769680291ba32687cb",
            "Names": "血脂偏高",
            "Gender": "不限",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{总胆固醇(>5.18) or 甘油三酯(>1.7) or 低密度脂蛋白胆固醇(>130)}"
        },
        {
            "Id": "239b78f5fb4841b4ab545208b782f77f",
            "Names": "肥胖",
            "Gender": "不限",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{体重指数(>28) }"
        },
        {
            "Id": "0393ccff09564e7db83313d6c37deb6a",
            "Names": "高血压",
            "Gender": "不限",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{收缩压(mmHg)(>140) or 舒张压(mmHg)(>90)}"
        },
        {
            "Id": "b4465bba1ca5481b912c1df445c55bbf",
            "Names": "同型半胱氨酸偏高（女）",
            "Gender": "女性",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{同型半胱氨酸(>13.56) }"
        },
        {
            "Id": "1bbae5d4d0804be5bbfb31ca7bab3dcd",
            "Names": "同型半胱氨酸偏高（男）",
            "Gender": "男性",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{同型半胱氨酸(>16.2) }"
        },
        {
            "Id": "90114592e3744c9da78b8cd05334eb43",
            "Names": "尿酸偏高（女）",
            "Gender": "女性",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{尿酸(>357) }"
        },
        {
            "Id": "66617627cca8421db0c0d5218805c19f",
            "Names": "尿酸偏高（男）",
            "Gender": "男性",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{尿酸(>428) }"
        },
        {
            "Id": "b37dd4d9054f4af79c24a04d616eac0d",
            "Names": "肌酐偏高（女）",
            "Gender": "女性",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{肌酐(>81) }"
        },
        {
            "Id": "0e3885d6adab423aa2f0fa45126abd04",
            "Names": "肌酐偏高（男）",
            "Gender": "男性",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{肌酐(>111) }"
        },
        {
            "Id": "ff358bdec03c46f9aea0ed5a44ad9c86",
            "Names": "血糖偏高",
            "Gender": "不限",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "黄色",
            "Judge": "{葡萄糖(≥6.1且≤7) }"
        },
        {
            "Id": "7143d9ed64f34e65a6c254ab30c7c8d9",
            "Names": "重度高甘油三酯血症",
            "Gender": "不限",
            "Age": "20-80",
            "LibraryName": "大庆油田健康管理中心体检库",
            "Levels": "橙色",
            "Judge": "{甘油三酯(>11.1) }"
        }
    ],
    "total": 11
}

Mock.api('/api/positive/setting', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: data_setting.total,
            records: data_setting.data
        }
    }
})

Mock.api('/api/positive/log', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: 0,
            records: []
        }
    }
})

Mock.api('/api/positive/task', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: 0,
            records: []
        }
    }
})

Mock.api('/api/positive/new', 'get', (req, res) => {
    console.log(req, res)
    return {
        code: 200,
        data: {
            total: 0,
            records: []
        }
    }
})